import router from './../router/index'
import store from './../store/index'

import utils from './utils'

/**
 *
 * @param {*} path
 * @param {RequestInit} options
 * @returns
 */
async function apiFetch(path, options) {
  console.log('[alex][apiFetch] start')
  //const token = localStorage.token

  const token = store.state.token

  const url = 'https://hslssqwjknieeziec7ha7yo3fy0butak.lambda-url.eu-west-3.on.aws' + path

  if (!options) options = { headers: {} }
  if (!options.headers) options.headers = {}
  //options.mode = 'no-cors'
  if (token) {
    options.headers['xvi-token'] = token
  }

  const x = await fetch(url, options)

  if (x.status == 403) {
    localStorage.token = ''
    if (router.currentRoute.path != '/auth') router.push('/auth?redirect=' + router.currentRoute.path)
  }
  return x
}

function username() {
  const token = store.state.token
  if (!token) return null
  return token.split('::')[0]
}

function userAppRoutes() {
  let profileMenuText = username() ? 'Usuario: ' + username() : 'Log in'

  let x = [
    { name: profileMenuText, to: '/auth', icon: 'mdi-account', subtitle: '' },
    { name: 'Paraguas', to: '/paraguas', icon: 'mdi-umbrella', subtitle: '' },
    { name: 'Privacidad', to: '/privacidad', icon: 'mdi-shield', subtitle: '' },
  ]

  if (profileMenuText != 'Log in') {
    x.push({ name: 'Home', to: '/', icon: 'mdi-home' })
    x.push({
      name: 'Listas',
      to: '/share-asins',
      icon: 'mdi-share',
    })

    //pregnancy

    const labor_due = new Date('2024-12-31')
    const labor_start = new Date(labor_due.getTime() - 280 * 24 * 60 * 60 * 1000) // Assuming pregnancy lasts 280 days
    const labor_week = utils.dates.diffToday(labor_start, ['weeks', 'days'])

    x.push({
      name: 'Pregnacy',
      to: '/embarazo',
      icon: 'mdi-human-pregnant',
      subtitle: labor_week.weeks + 'w ' + labor_week.days + 'd',
    })
    x.push({ name: 'Settings', to: '/settings', icon: 'mdi-cog' })
    x.push({ name: 'Text', to: '/text', icon: 'mdi-pencil' })
    x.push({ name: 'Code', to: '/code', icon: 'mdi-code-tags' })
    x.push({ name: 'Scan', to: '/barcode', icon: 'mdi-qrcode' })
    x.push({ name: 'Friends', to: '/friends', icon: 'mdi-ambulance' })
    x.push({ name: 'Food', to: '/food', icon: 'mdi-food' })
    x.push({ name: 'Calendar', to: '/gcalendar', icon: 'mdi-calendar' })
    x.push({ name: 'Tasks', to: '/todos', icon: 'mdi-calendar-check' })
    x.push({ name: 'Quotes', to: '/quotes', icon: 'mdi-pencil' })
    x.push({ name: 'Facturas', to: '/facturas', icon: 'mdi-invoice' })
    x.push({ name: 'Groceries', to: '/groceries', icon: 'mdi-cart-variant' })
    x.push({ name: 'Baby Tracker', to: '/baby-tracker', icon: 'mdi-baby' })
  }
  return x
}

export default { apiFetch, userAppRoutes }
