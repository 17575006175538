<template>
  <div>
    <h1>Baby Nerd Tracker</h1>

    <div v-if="babies">
      <v-chip v-for="baby in babies" :key="baby.id">
        {{ baby.name }}
      </v-chip>
    </div>

    <!-- list-->
    <v-list>
      <v-list-item v-for="event in events" :key="event.id" @click="editEvent(event)">
        <v-list-item-content>
          <v-list-item-title>
            {{ event.type }}
          </v-list-item-title>
          <v-list-item-subtitle>x hours ago</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-list-item-action-text>{{ event.timestamp }}</v-list-item-action-text>
        </v-list-item-action>
      </v-list-item>
    </v-list>

    <!--add button-->
    <v-fab-transition>
      <v-btn fab bottom right fixed color="primary" @click="addNewItem" :loading="loading">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>

    <!--edit dialog-->
    <v-dialog v-if="editing" v-model="editing" scrollable max-width="600">
      <v-card>
        <v-card-title v-if="false">Event</v-card-title>
        <v-card-text class="mt-4">
          <v-select
            label="baby"
            :items="
              babies.map((x) => {
                return { text: x.name, value: x.id }
              })
            "
            v-model="item_edit.baby_id"
          ></v-select>
          <v-select label="type" :items="['bibe', 'peso']" v-model="item_edit.type"></v-select>

          <v-text-field label="when" type="datetime-local" v-model="item_edit.when"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="editing = false" depressed color="error">cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="save" depressed color="success">save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- events (debug) -->
    <div style="font-family: monospace; margin-top: 50px; font-size: 8pt">
      <!-- selected baby-->
      <div>
        {{ baby_id }}
      </div>
      <div style="margin-top: 20px">
        {{ events }}
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/assets/api'

export default {
  data() {
    return {
      babies: null,
      baby_id: '',
      events: [],
      editing: false,
      item_edit: {},
    }
  },
  methods: {
    getCurrentDateTimeLocal() {
      const now = new Date()
      const offset = now.getTimezoneOffset()
      const adjustedDate = new Date(now.getTime() - offset * 60 * 1000)
      return adjustedDate.toISOString().slice(0, 16)
    },
    async fetchBabies() {
      let x = await api.apiFetch('/baby-tracker/babies/')
      if (x.ok) {
        this.babies = await x.json()
        this.baby_id = this.babies?.[0].id
        this.fetchEvents()
      }
    },
    async fetchEvents() {
      if (!this.baby_id) return
      let x = await api.apiFetch('/baby-tracker/events?baby_id=' + this.baby_id)
      if (x.ok) {
        this.events = await x.json()
      }
    },
    editEvent(event) {
      this.item_edit = { ...event }
      this.editing = true
    },
    addNewItem() {
      this.item_edit = {
        baby_id: this.baby_id,
        type: 'bibe',
        when: this.getCurrentDateTimeLocal(),
      }
      this.editing = true
    },
    async save() {
      let x = await api.apiFetch('/baby-tracker/events', {
        method: 'POST',
        body: JSON.stringify(this.item_edit),
      })
      if (x.ok) {
        this.editing = false
        this.fetchEvents()
      }
    },
  },
  mounted() {
    this.fetchBabies()
  },
}
</script>

<style>
input::-webkit-date-and-time-value {
  text-align: left !important;
}
</style>
